<template>
  <div></div>
</template>

<script>
export default {
  name: 'Welcome',

  mounted() {
    // This is just a placeholder page
    // We immediately re-direct to V2
    window.location.href = `${process.env.VUE_APP_V2_BASE_URL}/welcome`
  },
}
</script>

<style scoped></style>
